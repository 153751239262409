import React from "react";
import Markdown from "markdown-to-jsx";
import Layout from "../components/Layout/Layout";
import { graphql } from "gatsby";
import Styled from "@emotion/styled";
import { css } from "@emotion/core";
import { theme } from "../utils/global-styles/theme";

const PageMargins = css`
    margin-left: 212px;
    margin-right: 212px;

    @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
      margin-left: 158px;
      margin-right: 158px;
    }

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      margin-left: 40px;
      margin-right: 40px;
      display: block;
      overflow: auto;
      word-wrap: break-word;
    }
  `,
  PageHeader = Styled.h2`
  margin-top: 50px;
  letter-spacing: -1.38px;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    margin-top: 20px;
    letter-spacing: -0.83px;
  }
  ${PageMargins}
`,
  PageDescription = Styled.p`
  margin-top: 40px;
  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    margin-top: 20px;
    margin-bottom: 50px;
  }
  ${PageMargins}
  `,
  SectionTitle = Styled.h6`
    margin-bottom: 20px;
    color: ${theme.colors.black};
    font-weight: bold;
    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      margin-top: 10px;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.18px;
    }
  `,
  Break = Styled.hr`
    height: 2px;
    border-color: ${theme.colors.accent};
    margin-top: 50px;
    margin-bottom: 50px;

    margin-left: 125px;
    margin-right: 125px;

    @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
      margin-left: 71px;
      margin-right: 71px;
    }

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      height: 1px;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  `,
  BottomPadding = Styled.div`
    margin-bottom: 50px;
  `;

const ContactPage = ({ path, data: { ContactPage } }) => {
  const intro = ContactPage.contentModules[0],
    sections = ContactPage.contentModules.slice(1);

  return (
    <Layout
      isDetailView={false}
      currentPath={path}
      key="contact-us"
      marketingModal={ContactPage.marketingModal}
    >
      <PageHeader>{ContactPage.heading}</PageHeader>
      <PageDescription className="p4">
        <Markdown>{intro.description.description}</Markdown>
      </PageDescription>
      <Break />
      {sections.map(x => (
        <div key={x.id}>
          <div css={PageMargins}>
            <SectionTitle className="h6 black">{x.title}</SectionTitle>
            <Markdown
              className="contact-us-paragraph"
            >
              {x.description.description}
            </Markdown>
          </div>
          <Break />
        </div>
      ))}
      <BottomPadding />
    </Layout>
  );
};

export const query = graphql`
  query ContactPage {
    ContactPage: contentfulPageLayout(
      queryIdentifier: { eq: "Contact Us Page" }
    ) {
      title
      heading
      shortDescription
      contentModules {
        ... on ContentfulTitleDescription {
          id
          title
          description {
            description
          }
        }
      }
      marketingModal {
        ... on ContentfulMarketingModal {
          id
          cta {
            linkText
            url
          }
          description {
            description
          }
          title
          showAfterPercent
          hubspotListId
          marketingModalImage {
            image {
              fluid {
                aspectRatio
                srcSet
              }
            }
          }
          formFields
          successTitle
          successDescription
          additionalText {
            json
          }
        }
      }
    }
  }
`;

export default ContactPage;
